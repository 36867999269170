import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDNykxzkJNpN1--OojaWTVFTjOI5_3ho1U',
  authDomain: 'mybook-labake.firebaseapp.com',
  projectId: 'mybook-labake',
  storageBucket: 'mybook-labake.appspot.com',
  messagingSenderId: '113214169894',
  appId: '1:113214169894:web:a43b35a9507a99eba9d7e7',
  measurementId: 'G-QX8GXMCCNV',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
